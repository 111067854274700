import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { LANGUAGE } from 'constants/index'
import { store } from 'store/configureStore'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    supportedLngs: Object.values(LANGUAGE),
    lng: store.getState().router.location.query.language, // 預設語言
    fallbackLng: LANGUAGE.EN, // 如果當前切換的語言沒有對應的翻譯則使用這個語言，
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
