// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_container__1Wgi0{border:.1rem #33c4a8 solid;border-radius:.5rem;padding:.8rem 3.5rem;color:#fff;font-size:1.6rem;background-color:rgba(0,0,0,0);transition:background-color .2s ease-out}.Button_container__1Wgi0:disabled{border-color:rgba(51,196,168,.5019607843);color:rgba(255,255,255,.5019607843);cursor:not-allowed}@media not all and (hover: none), (-ms-high-contrast: none) {.Button_container__1Wgi0:hover:not(:disabled){background-color:#4d4d4d}}", "",{"version":3,"sources":["webpack://src/components/Modal/Button/Button.module.scss","<no source>"],"names":[],"mappings":"AAAA,yBACE,0BAAA,CACA,mBAAA,CACA,oBAAA,CACA,UAAA,CACA,gBAAA,CACA,8BAAA,CACA,wCAAA,CACA,kCACE,yCAAA,CACA,mCAAA,CACA,kBAAA,CCXJ,6DAAA,8CAAA,yBAAA,CAAA","sourcesContent":[".container {\n  border: 0.1rem #33c4a8 solid;\n  border-radius: 0.5rem;\n  padding: 0.8rem 3.5rem;\n  color: #fff;\n  font-size: 1.6rem;\n  background-color: transparent;\n  transition: background-color 0.2s ease-out;\n  &:disabled {\n    border-color: #33c4a880;\n    color: #ffffff80;\n    cursor: not-allowed;\n  }\n  &:hover:not(:disabled) {\n    background-color: lighten(#000, 30%);\n  }\n}\n",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Button_container__1Wgi0"
};
module.exports = ___CSS_LOADER_EXPORT___;
