import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const orientationMaps = {
  0: 'portrait',
  90: 'landscape',
  '-90': 'landscape',
  180: 'portrait',
  '-180': 'portrait',
}

const useOrientationDetect = (action) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // if ('onorientationchange' in window) {
    //   window.addEventListener('orientationchange', (e) => {
    //     const { orientation, screen } = e.target
    //     if (typeof orientation === 'number') {
    //       dispatch(action(orientationMaps[orientation]))
    //     } else if (screen) {
    //       dispatch(action(orientationMaps[e.target.screen.orientation.angle]))
    //     }
    //   })
    // } else {
    const mql = window.matchMedia('(orientation: portrait), (max-aspect-ratio: 4 / 3)')
    if (mql && typeof mql.addEventListener === 'function') {
      mql?.addEventListener('change', (m) => {
        dispatch(action(m.matches ? 'portrait' : 'landscape'))
      })
    }
    // }
  }, [dispatch, action])
}

export default useOrientationDetect
