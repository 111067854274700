import PropTypes from 'prop-types'
import React from 'react'
import useStyles from 'hooks/useStyles'
import classNames from 'classnames'
import { ICON_NAMES } from 'constants/index'
import { ReactComponent as Player } from 'assets/player.svg'
import { ReactComponent as Table } from 'assets/table.svg'
import { ReactComponent as Info } from 'assets/info.svg'
import { ReactComponent as Chat } from 'assets/chat.svg'
import { ReactComponent as Contact } from 'assets/contact.svg'
import { ReactComponent as Mouse } from 'assets/mouse.svg'
import { ReactComponent as Fullscreen } from 'assets/fullscreen.svg'
import { ReactComponent as LayoutSmall } from 'assets/layout-small.svg'
import { ReactComponent as LayoutMedium } from 'assets/layout-medium.svg'
import { ReactComponent as LayoutLarge } from 'assets/layout-large.svg'
import { ReactComponent as Bell } from 'assets/bell.svg'
import { ReactComponent as Setting } from 'assets/setting.svg'
import { ReactComponent as IOSCheck } from 'assets/ios-check.svg'
import { ReactComponent as IOSCross } from 'assets/ios-cross.svg'
import { ReactComponent as Sound } from 'assets/sound.svg'
import { ReactComponent as Mute } from 'assets/mute.svg'
import { ReactComponent as History } from 'lib/assets/icons/history.svg'
import styles from './Icon.module.scss'

const iconMaps = {
  [ICON_NAMES.PLAYER]: <Player />,
  [ICON_NAMES.TABLE]: <Table />,
  [ICON_NAMES.INFO]: <Info />,
  [ICON_NAMES.CHAT]: <Chat />,
  [ICON_NAMES.CONTACT]: <Contact />,
  [ICON_NAMES.MOUSE]: <Mouse />,
  [ICON_NAMES.FULLSCREEN]: <Fullscreen />,
  [ICON_NAMES.LAYOUT_SMALL]: <LayoutSmall />,
  [ICON_NAMES.LAYOUT_MEDIUM]: <LayoutMedium />,
  [ICON_NAMES.LAYOUT_LARGE]: <LayoutLarge />,
  [ICON_NAMES.BELL]: <Bell />,
  [ICON_NAMES.SETTING]: <Setting />,
  [ICON_NAMES.IOSCHECK]: <IOSCheck />,
  [ICON_NAMES.IOSCROSS]: <IOSCross />,
  [ICON_NAMES.SOUND]: <Sound />,
  [ICON_NAMES.MUTE]: <Mute />,
  [ICON_NAMES.HISTORY]: <History />,
}

const Icon = ({
  name,
  className,
  title,
  surroundingGap = false,
  onClick,
  onMouseEnter,
  onAnimationEnd,
}) => {
  useStyles(styles)
  return (
    <span
      aria-hidden="true"
      className={classNames(styles.container, surroundingGap && styles.surroundingGap, className)}
      title={title}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onAnimationEnd={onAnimationEnd}
    >
      <span className={styles.icon}>{iconMaps[name]}</span>
    </span>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  surroundingGap: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onAnimationEnd: PropTypes.func,
}

export default Icon
