import { call, takeEvery, put, fork, select } from 'redux-saga/effects'
import { updateCustomInfo } from 'reducers/layout'
import { getCustomInfo } from 'services/api'

function* requestCustomInfo(accessToken) {
  try {
    const { logoH, logoV } = yield select((state) => state.layout)
    if (!accessToken || (logoH && logoV)) return
    const { data, status } = yield call(getCustomInfo, { token: accessToken })
    if (status.code === '0') {
      yield put(updateCustomInfo(data.logo))
    }
  } catch (error) {
    console.error(error)
  }
}
function handleCustomInfo({ payload }) {
  if (payload.title) {
    document.title = payload.title
    document.head.querySelector('meta[name="description"]').content = payload.title
  }
  if (payload.favicon) {
    const link = document.createElement('link')
    link.rel = 'icon'
    link.href = payload.favicon
    document.head.appendChild(link)
  }
}

export default function* watchers() {
  const token = yield select((state) => state.router.location.query.token)
  const accessToken = sessionStorage.getItem(token)
  yield fork(requestCustomInfo, accessToken)
  yield takeEvery(updateCustomInfo, handleCustomInfo)
}
