import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'webSocket',
  initialState: {
    readyState: WebSocket.CLOSED,
    mountWSEvents: false,
    closeCode: undefined,
  },
  reducers: {
    updateReadyState: (state, { payload }) => {
      state.readyState = payload
    },
    updateMountWsEvents: (state, { payload }) => {
      state.mountWSEvents = payload
    },
    updateCloseCode: (state, { payload }) => {
      state.closeCode = payload
    },
  },
})

export const { updateReadyState, updateMountWsEvents, updateCloseCode } = slice.actions
export default slice.reducer
