import useSound from 'use-sound'
import { useSelector } from 'react-redux'

const useSoundEffect = (source, { volume, loop = false, callbacks = {} } = {}) => {
  const mainVolume = useSelector((state) => state.global.mainVolume)
  const mainSoundAllowed = useSelector((state) => state.global.mainSoundAllowed)
  const formattedVolume = mainSoundAllowed ? (mainVolume / 100) * volume : 0
  const [play, { pause, stop, duration, sound }] = useSound(source, {
    volume: formattedVolume,
    loop,
  })
  if (sound?.volume && sound.volume() !== formattedVolume) {
    sound.volume(formattedVolume)
  }
  return {
    play,
    pause,
    stop,
    duration,
    sound,
    callbacks,
  }
}

export default useSoundEffect
