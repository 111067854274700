/* eslint-disable react/jsx-props-no-spreading */
import { createContext, useState, useRef, useContext, useCallback } from 'react'
import { ANIMATION_NAMES } from 'constants/index'
import Modal from 'components/Modal/Modal'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

export const DialogServiceContext = createContext(null)
export const useDialog = () => useContext(DialogServiceContext)

export const DialogServiceProvide = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState({})
  const [Content, setContent] = useState({})
  const promiseRef = useRef()
  const nodeRef = useRef()

  const openDialog = useCallback(({ customContent, ...otherOptions }) => {
    setOpen(true)
    setOptions(otherOptions)
    setContent({ Component: customContent })
    return new Promise((resolve, reject) => {
      promiseRef.current = { resolve, reject }
    })
  }, [])

  const closeDialog = useCallback((closeCB) => {
    setOpen(false)
    closeCB?.()
  }, [])

  const handleOnExit = useCallback(() => {
    setOptions({})
    setContent({})
  }, [])

  const handleCancel = useCallback(
    (cancelCB) => {
      if (promiseRef.current) {
        promiseRef.current.reject()
      }
      cancelCB?.()
      closeDialog()
    },
    [closeDialog],
  )

  const handleConfirm = useCallback(
    async (data, confirmCB) => {
      if (promiseRef.current) {
        promiseRef.current.resolve(data)
      }
      confirmCB?.()
      closeDialog()
    },
    [closeDialog],
  )

  const { Component: CustomContent } = Content
  const { title, message, withConfirm, withCancel, icon, block, topmost } = options
  return (
    <>
      <DialogServiceContext.Provider value={openDialog}>{children}</DialogServiceContext.Provider>
      <CSSTransition
        nodeRef={nodeRef}
        onExited={handleOnExit}
        in={open}
        timeout={300}
        classNames={ANIMATION_NAMES.FADE_IN_OUT}
        unmountOnExit
      >
        <Modal
          ref={nodeRef}
          title={title}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          withConfirm={withConfirm}
          withCancel={withCancel}
          icon={icon}
          block={block}
          topmost={topmost}
        >
          {CustomContent && (
            <CustomContent
              onClose={closeDialog}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
            />
          )}
          {message}
        </Modal>
      </CSSTransition>
    </>
  )
}

DialogServiceProvide.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}
