import { webSocketConnectTimeout } from 'config'
import { updateReadyState, updateCloseCode } from 'reducers/webSocket'
import { updateGlobalSetting, updateSubscriptions } from 'reducers/global'
import { WS_ERROR_CODE } from 'constants/index'

class WebSocketUtility {
  #ws = null

  #url = ''

  #dispatch = null

  #emit = null

  #listenerStore = {}

  constructor(url, dispatch) {
    this.#url = url
    this.#dispatch = dispatch
  }

  #updateReadyState = () => {
    this.#dispatch?.(updateReadyState(this.#ws.readyState))
  }

  #updateCloseCode = (code) => {
    this.#dispatch?.(updateCloseCode(code))
  }

  connect = (autoRetry = false) => {
    console.warn('ws connecting')
    this.#ws = new WebSocket(this.#url)
    this.#updateReadyState()
    this.#onError()
    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        resolve(false)
        if (autoRetry) this.#reconnect()
      }, webSocketConnectTimeout)

      this.#ws.onopen = () => {
        this.#updateReadyState()
        clearTimeout(timer)
        this.#onClose()
        resolve(true)
      }
    })
  }

  disconnect = () => {
    if (this.#ws && this.#ws.readyState === WebSocket.OPEN) {
      this.#updateReadyState()
      this.#ws.close()
      return new Promise((resolve) => {
        this.#ws.onclose = () => {
          resolve('on close')
        }
      })
    }
  }

  #reconnect = async () => {
    console.warn('ws reconnecting')
    await this.disconnect()
    await this.connect(true)
    await this.createMessageEmitter()
  }

  #updateSubscriptions = (data) => {
    this.#ws.send(
      JSON.stringify({
        action: data.type,
        data: data.payload,
      }),
    )
  }

  #updateGlobalSetting = ({ payload }) => {
    this.#ws.send(
      JSON.stringify({
        action: 'UpdateGlobalSetting',
        data: payload,
      }),
    )
  }

  // #requestOrderHistory = (data) => {
  //   this.#ws.send(
  //     JSON.stringify({
  //       action: 'OrderHistory',
  //       data,
  //     }),
  //   )
  // }

  getListener = (action) => {
    return this.#listenerStore[action]
  }

  syncRequest = ({ action, data }) => {
    return new Promise((resolve) => {
      this.#listenerStore[action] = resolve
      this.#ws.send(
        JSON.stringify({
          action,
          data,
        }),
      )
    })
  }

  getEventMap = () => {
    return {
      [updateSubscriptions.type]: this.#updateSubscriptions,
      [updateGlobalSetting.type]: this.#updateGlobalSetting,
    }
  }

  createMessageEmitter = (emit) => {
    if (!this.#emit) this.#emit = emit
    this.#ws.onmessage = (message) => this.#emit(message)
  }

  #onError = () => {
    this.#ws.onerror = (e) => {
      console.error(e)
    }
  }

  #onClose = () => {
    this.#ws.onclose = (e) => {
      this.#updateReadyState()
      this.#updateCloseCode(e.code)
      console.warn(e)
      if (!WS_ERROR_CODE[e.code]) {
        const timer = setTimeout(() => {
          this.#reconnect()
          clearTimeout(timer)
        }, webSocketConnectTimeout)
      }
    }
  }
}

export default WebSocketUtility
