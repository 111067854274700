import { fork } from 'redux-saga/effects'
import createWebSocketSaga from './webSocketSaga'
import layoutSaga from './layoutSaga'
import globalSaga from './globalSaga'

export default function* watchers() {
  yield fork(createWebSocketSaga)
  yield fork(layoutSaga)
  yield fork(globalSaga)
}
