import { eventChannel } from 'redux-saga'
import { parse } from 'query-string'
import { call, take, takeEvery, put, fork, delay, select } from 'redux-saga/effects'
import {
  updateCategories,
  updatePlayerCount,
  updateBalance,
  updateGoodRoadCount,
  getGlobalSetting,
  requestOrderHistory,
  requestOrderSummary,
} from 'reducers/global'
import {
  updateTableList,
  updateTableDetail,
  updatePlayerTable,
  updateSeats,
  changeDealer,
  updateRoad,
  updateGoodRoad,
  updateState,
  updateStreamer,
} from 'reducers/tables'
import { updateMountWsEvents } from 'reducers/webSocket'
import { updateCustomInfo } from 'reducers/layout'
import { store } from 'store/configureStore'
import jsonParser from 'utils/jsonParser'
import WebSocketUtility from 'services/webSocketUtility'
import { getAuthToken } from 'services/api'

let ws
export const getWS = () => ws

const actionMaps = {
  Tables: updateTableList,
  TableDetails: updateTableDetail,
  UpdateSeats: updateSeats,
  ChangeDealer: changeDealer,
  Categories: updateCategories,
  CasinoPlayerCount: updatePlayerCount,
  PlayerTable: updatePlayerTable,
  UpdateBalance: updateBalance,
  UpdateRoad: updateRoad,
  UpdateGoodRoad: updateGoodRoad,
  UpdateGoodRoadCount: updateGoodRoadCount,
  UpdateState: updateState,
  GlobalSetting: getGlobalSetting,
  OrderHistory: requestOrderHistory,
  OrderSummary: requestOrderSummary,
  StreamerLive: updateStreamer,
}

function* getWSAccessToken(token) {
  try {
    const accessToken = sessionStorage.getItem(token)
    if (accessToken) return accessToken
    const { data, status } = yield call(getAuthToken, { token })
    if (status.code === '0') {
      sessionStorage.setItem(token, data.token)
      yield put(updateCustomInfo(data.logo))
      return data.token
    }
    return null
  } catch (error) {
    console.error(error)
  }
}

function createEventChannel(ws) {
  try {
    return eventChannel((emit) => {
      ws.createMessageEmitter(emit)
      return () => {
        ws.disconnect()
      }
    })
  } catch (e) {
    throw new Error(e)
  }
}

function* mountWSEvents(ws) {
  const eventMap = ws.getEventMap()
  const eventKeys = Object.keys(eventMap)

  for (let i = 0; i < eventKeys.length; i += 1) {
    yield takeEvery(eventKeys[i], eventMap[eventKeys[i]])
  }
}

function* actionsForward({ data, action: actionName }, ws) {
  const action = actionMaps[actionName]
  if (action) {
    ws?.getListener(actionName)?.(data)
    yield put(action?.(data))
  }
}

function* createWSMessageReceiver(ws) {
  const channel = yield call(createEventChannel, ws)
  while (true) {
    const { data } = yield take(channel)
    const payload = jsonParser(data)
    yield call(actionsForward, payload, ws)
  }
}

export default function* createWebSocketSaga() {
  const { host, wsScheme } = yield select((state) => state.global)
  const BASIC_WS_URL = `${wsScheme}//${host}/api/lobby/ws`
  const search = new URLSearchParams(window.location.search)
  const token = search.get('token')
  const accessToken = yield call(getWSAccessToken, token)
  const { tableid, tabletype, language } = parse(window.location.search)
  search.set('token', accessToken)

  if (tabletype && tableid) {
    window.location.href = `//${host}/${tabletype}/?token=${token}&tableId=${tableid}&language=${language}`
  }
  try {
    ws = new WebSocketUtility(`${BASIC_WS_URL}?${search.toString()}`, store.dispatch)
    let isConnected = false
    while (!isConnected) {
      isConnected = yield call(ws.connect)
      if (isConnected) break
      yield delay(1000)
    }

    yield fork(mountWSEvents, ws)
    store.dispatch(updateMountWsEvents(true))
    yield fork(createWSMessageReceiver, ws)
  } catch (e) {
    console.log(e)
  }
}
