import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useSoundEffect from 'hooks/useSoundEffect'
import bgmChinese from 'assets/sounds/bgm-chinese.mp3'
import clickSound from 'assets/sounds/click.mp3'
import { BGM_PLAYBACK_KEY } from 'constants/index'
import jsonParser from 'utils/jsonParser'

export const SoundEffectContext = React.createContext()

const SoundEffectProvider = ({ children }) => {
  const bgmCallbacks = useMemo(
    () => ({
      savePlayback: (playback) => {
        sessionStorage.setItem(BGM_PLAYBACK_KEY, playback)
      },
      getPlayback: () => {
        const playback = jsonParser(sessionStorage.getItem(BGM_PLAYBACK_KEY))
        sessionStorage.removeItem(BGM_PLAYBACK_KEY)
        return playback
      },
    }),
    [],
  )
  const mainSoundAllowed = useSelector((state) => state.global.mainSoundAllowed)
  const bgmAllowed = useSelector((state) => state.global.bgmAllowed)
  const bgmVolume = useSelector((state) => state.global.bgmVolume)
  const effectSoundAllowed = useSelector((state) => state.global.effectSoundAllowed)
  const effectVolume = useSelector((state) => state.global.effectVolume)
  const isWindowBlur = useSelector((state) => state.global.isBlur)

  const backgroundMusic = useSoundEffect(bgmChinese, {
    volume: mainSoundAllowed && bgmAllowed && !isWindowBlur ? bgmVolume / 100 : 0,
    loop: true,
    callbacks: bgmCallbacks,
  })
  const click = useSoundEffect(clickSound, { volume: effectSoundAllowed ? effectVolume / 100 : 0 })
  return (
    <SoundEffectContext.Provider value={{ backgroundMusic, click }}>
      {children}
    </SoundEffectContext.Provider>
  )
}

SoundEffectProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default SoundEffectProvider
