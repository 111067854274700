import { put, select, call, fork, take, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { updateState } from 'reducers/tables'
import {
  updateCategory,
  updateSubscriptions,
  updatePreBetAmount,
  updateMarbleMaintain,
} from 'reducers/global'
import { updateGameMultiplay } from 'reducers/layout'
import jsonParser from 'utils/jsonParser'

function* categoryChangeHander() {
  let prevCategory = yield select((state) => state.global.category)
  while (true) {
    const {
      payload: { category: categoryId },
    } = yield take(updateCategory)
    const topic = { topic: 'category', categoryId }
    let prevTopic = []

    if (prevCategory && prevCategory !== categoryId) {
      prevTopic = [{ ...topic, categoryId: prevCategory }]
    }
    yield put(
      updateSubscriptions({
        subscribeTopics: [topic],
        unsubscribeTopics: prevTopic,
      }),
    )
    prevCategory = categoryId
  }
}

function createEventChannel() {
  try {
    return eventChannel((emit) => {
      const callback = ({ key, newValue, oldValue }) => {
        emit({ key, newValue, oldValue })
      }
      window.addEventListener('storage', callback)
      return () => callback
    })
  } catch (e) {
    throw new Error(e)
  }
}

function* mulyiplayChangeHandler() {
  const showMultiplay = jsonParser(sessionStorage.getItem('showMultiplay'))
  yield put(updateGameMultiplay(showMultiplay))
}

function* storageChangeHandler() {
  const inInternalIframe = yield select((state) => state.global.inInternalIframe)
  if (inInternalIframe) {
    const isMultiplay = jsonParser(sessionStorage.getItem('showMultiplay'))
    const preBetAmount = jsonParser(
      sessionStorage.getItem(`${isMultiplay ? 'multiplay' : 'baccarat'}:pba`),
    )
    if (preBetAmount) yield put(updatePreBetAmount(preBetAmount))
  } else {
    sessionStorage.removeItem('baccarat:pba')
    sessionStorage.removeItem('multiplay:pba')
  }
  const channel = yield call(createEventChannel)
  while (true) {
    const { key, newValue } = yield take(channel)
    if (key.endsWith('pba')) {
      yield put(updatePreBetAmount(+newValue))
    }
    if (key === 'showMultiplay') {
      yield call(mulyiplayChangeHandler)
    }
  }
}

function* handleMarbleState({ payload }) {
  const { state, tableId } = payload
  if (tableId === 'MC01') {
    yield put(updateMarbleMaintain(state === 'Maintain'))
  }
}

export default function* watchers() {
  yield takeEvery(updateState, handleMarbleState)
  yield fork(categoryChangeHander)
  yield fork(storageChangeHandler)
}
