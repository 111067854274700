import React, { useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import useStyles from 'hooks/useStyles'
import styles from './Button.module.scss'

const Button = ({ label, onClick = () => {}, disabled }) => {
  useStyles(styles)
  const handleClick = useCallback(
    (e) => {
      onClick(e)
    },
    [onClick],
  )
  return (
    <button disabled={disabled} className={styles.container} onClick={handleClick}>
      {label}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default memo(Button)
