const checkIframe = () => {
  const inIframe = window !== window.parent
  let inInternalIframe = false
  try {
    if (
      inIframe &&
      (process.env.NODE_ENV === 'development' ||
        window.parent.location.host === window.location.host)
    ) {
      inInternalIframe = true
    }
  } catch (error) {
    console.warn(error)
  }
  return { inIframe, inInternalIframe }
}

export default checkIframe
