import { createSlice } from '@reduxjs/toolkit'
import { updateCategory } from 'reducers/global'
import { updateTableList } from 'reducers/tables'
import jsonParser from 'utils/jsonParser'
import { DEVICE_WIDTH } from '../constants'

const device = {
  isBelowIpad: window.innerWidth <= DEVICE_WIDTH.IPAD,
  isBelowIphoneProMax: window.innerWidth <= DEVICE_WIDTH.IPHONE_13_PRO_MAX,
}

export const slice = createSlice({
  name: 'layout',
  initialState: {
    logoH: '',
    logoV: '',
    logoHW: '',
    logoVW: '',
    favicon: '',
    title: '',
    contentTop: 0,
    gridLayout: 0,
    device,
    tableLoading: false,
    preventScroll: false,
    showMultiplayBtn: true,
    showGameMultiplay: jsonParser(sessionStorage.getItem('showMultiplay')),
    innerHeight: window.innerHeight,
    orientation: window.matchMedia('(orientation: portrait)').matches ? 'portrait' : 'landscape',
  },
  reducers: {
    updateCustomInfo: (state, { payload }) => {
      const { logoH, logoV, logoHW, logoVW, favicon, title } = payload
      state.logoH = logoH
      state.logoV = logoV
      state.logoHW = logoHW
      state.logoVW = logoVW
      state.favicon = favicon
      state.title = title
    },
    changeContentTop: (state, { payload }) => {
      state.contentTop = payload.contentTop
    },
    changeLayout: (state, { payload }) => {
      state.gridLayout = payload.gridLayout
    },
    updatePreventScroll: (state, { payload }) => {
      state.preventScroll = payload
    },
    updateGameMultiplay: (state, { payload }) => {
      state.showGameMultiplay = payload
    },
    updateOrientation: (state, { payload }) => {
      state.orientation = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCategory, (state) => {
        state.tableLoading = true
      })
      .addCase(updateTableList, (state) => {
        state.tableLoading = false
      })
  },
})

export const {
  updateCustomInfo,
  changeLayout,
  changeContentTop,
  updatePreventScroll,
  updateGameMultiplay,
  updateOrientation,
} = slice.actions
export default slice.reducer
