import { createSlice } from '@reduxjs/toolkit'
import { GOOD_ROAD_PRIORITY } from 'constants/index'

export const slice = createSlice({
  name: 'tables',
  initialState: {
    list: [],
    detail: {},
    streamer: {},
    total: 0,
    playerTables: [],
    visibleTables: [],
  },
  reducers: {
    updateTableList: (state, { payload }) => {
      state.list = payload.tables
      state.total = payload.total
    },
    updateTableDetail: (state, { payload }) => {
      payload.tables?.forEach((table) => {
        state.detail[table.tableId] = {
          ...state.detail[table.tableId],
          ...table,
        }
      })
    },
    updateStreamer: (state, { payload }) => {
      payload.forEach((data) => {
        state.streamer[data.tableId] = {
          ...state.streamer[data.tableId],
          ...data,
        }
      })
    },
    updatePlayerTable: (state, { payload }) => {
      state.playerTables = payload.tableId
    },
    updateVisibleTables: (state, { payload }) => {
      state.visibleTables = payload.visibleTables
    },
    updateSeats: (state, { payload }) => {
      const tableDetail = state.detail[payload.tableId]
      if (tableDetail) {
        tableDetail.seats.occupied = payload.seats.occupied
      }
    },
    changeDealer: (state, { payload }) => {
      const tableDetail = state.detail[payload.tableId]
      if (tableDetail) {
        tableDetail.dealer = payload.dealer
      }
    },
    updateRoad: (state, { payload }) => {
      const tableDetail = state.detail[payload.tableId]

      if (tableDetail) {
        tableDetail.baccarat.bigRoad = payload.bigRoad
      }
    },
    updateState: (state, { payload }) => {
      const tableDetail = state.detail[payload.tableId]

      if (tableDetail) {
        tableDetail.state = payload.state
      }
    },
    updateGoodRoad: (state, { payload }) => {
      const tableDetail = state.detail[payload.tableId]

      if (payload.goodRoads.length > 1) {
        payload.goodRoads.sort((a, b) => {
          return GOOD_ROAD_PRIORITY.indexOf(a.type) - GOOD_ROAD_PRIORITY.indexOf(b.type)
        })
      }
      if (tableDetail) {
        tableDetail.goodRoads = payload.goodRoads
      }
    },
  },
})

export const {
  updateTableList,
  updateTableDetail,
  updatePlayerTable,
  updateVisibleTables,
  updateSeats,
  changeDealer,
  updateRoad,
  updateGoodRoad,
  updateState,
  updateStreamer,
} = slice.actions
export default slice.reducer
