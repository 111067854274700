import React, { forwardRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Icon from 'components/Icon/Icon'
import { updatePreventScroll } from 'reducers/layout'
import useStyles from 'hooks/useStyles'
import Button from './Button/Button'
import styles from './Modal.module.scss'

const Modal = forwardRef(
  (
    { title, children, onCancel, icon, onConfirm, withConfirm = true, withCancel, block, topmost },
    ref,
  ) => {
    useStyles(styles)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const handleConfirmBtnClick = useCallback(() => {
      onConfirm?.()
      dispatch(updatePreventScroll(false))
    }, [onConfirm, dispatch])

    const handleCancelBtnClick = useCallback(() => {
      onCancel?.()
      dispatch(updatePreventScroll(false))
    }, [onCancel, dispatch])

    useEffect(() => {
      if (block) {
        dispatch(updatePreventScroll(true))
      }
    }, [block, dispatch])

    return (
      <div ref={ref} className={classNames(styles.wrapper, topmost && styles.topmost)}>
        {block && <div className={styles.backdrop} />}
        <div className={styles.container}>
          {title && <div className={styles.header}>{title}</div>}
          <div className={styles.body}>
            {icon && (
              <div className={styles.iconWrapper}>
                <Icon name={icon} />
              </div>
            )}
            {children}
          </div>
          {(withConfirm || withCancel) && (
            <div className={styles.footer}>
              {withConfirm && <Button label={t('confirm')} onClick={handleConfirmBtnClick} />}
              {withCancel && <Button label={t('cancel')} onClick={handleCancelBtnClick} />}
            </div>
          )}
        </div>
      </div>
    )
  },
)
Modal.displayName = 'Modal'

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  icon: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  withConfirm: PropTypes.bool,
  withCancel: PropTypes.bool,
  block: PropTypes.bool,
}

export default Modal
