// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Icon_container__2LKk2{position:relative;width:2em;line-height:0;display:flow-root}.Icon_icon__2Th0s{width:100%;height:0;padding-top:50%;padding-bottom:50%;display:inline-flex;align-items:center}.Icon_icon__2Th0s>svg{position:absolute;width:100%;height:100%}.Icon_surroundingGap__xsl-d{margin:.5rem}", "",{"version":3,"sources":["webpack://src/components/Icon/Icon.module.scss"],"names":[],"mappings":"AAAA,uBACE,iBAAA,CACA,SAAA,CACA,aAAA,CACA,iBAAA,CAGF,kBACE,UAAA,CACA,QAAA,CACA,eAAA,CACA,kBAAA,CACA,mBAAA,CACA,kBAAA,CAEA,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CAIJ,4BACE,YAAA","sourcesContent":[".container {\n  position: relative;\n  width: 2em;\n  line-height: 0;\n  display: flow-root;\n}\n\n.icon {\n  width: 100%;\n  height: 0;\n  padding-top: 50%;\n  padding-bottom: 50%;\n  display: inline-flex;\n  align-items: center;\n\n  > svg {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.surroundingGap {\n  margin: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Icon_container__2LKk2",
	"icon": "Icon_icon__2Th0s",
	"surroundingGap": "Icon_surroundingGap__xsl-d"
};
module.exports = ___CSS_LOADER_EXPORT___;
