import PropTypes from 'prop-types'

export const SERVER_ACTIONS = {
  ORDER_HISTORY: 'OrderHistory',
  ORDER_SUMMARY: 'OrderSummary',
}

export const DEVICE_WIDTH = {
  IPHONE_13_PRO_MAX: 926,
  IPAD: 1366,
}

export const ROUTE_PATH = {
  BACCARAT: 'baccarat',
  TOP_GAMES: 'topgames',
  BLACK_JACK: 'blackjack',
  MARBLE: 'marble',
}

export const ANIMATION_NAMES = {
  SLIDE_LEFT_IN_OUT: 'slide-left-in-out',
  SLIDE_UP_IN_OUT: 'slide-up-in-out',
  FADE_IN_OUT: 'fade-in-out',
  LAZY_UNMOUNT: 'lazy-unmount',
}

export const MARK_DECODE_TYPE_MAPS = {
  B: 'banker',
  P: 'player',
  T: 'tie',
}

export const ROAD_TYPE_MAPS = {
  BIG: 'big',
  BIG_EYE: 'bigEye',
  SMALL: 'small',
  COCKROACH: 'cockroach',
  BEAD: 'bead',
}

export const DEFAULT_ROAD_MARK_WIDTH = 0.85
export const DEFAULT_ROAD_MARK_HEIGHT = 0.85

export const POST_MESSAGE_ACTION = {
  TOGGLE_MULTIPLAY: 'toggle-multiplay',
  CLOSE_MULTIPLAY: 'close-multiplay',
  CHANGE_TABLE: 'change-table',
  CHANGE_URL: 'change-url',
  FULL_SCREEN: 'full-screen',
  CHECK_BET: 'check-bet',
}

export const LANGUAGE = {
  EN: 'en',
  ZH_CN: 'zh-CN',
  JA: 'ja',
  ZH_TW: 'zh-TW',
  KO: 'ko',
  TH: 'th',
  VN: 'vn',
}

export const GOOD_ROAD_PRIORITY = [
  'longb',
  'longp',
  'singlebp',
  'repeatbp',
  'singlebdoublep',
  'singlepdoubleb',
  'doubleb',
  'doublep',
  'doublebp',
  'doublepb',
]

export const TABLE_STATE = {
  MAINTAIN: 'Maintain',
  DISABLED: 'Disabled',
}

export const WS_ERROR_CODE = {
  3000: 'tokenInvalid',
  3001: 'sessionClosed',
  3002: 'noTablesAvailable',
}

export const ERROR_CODE = {
  C0001: 'ckeckMultiplayBet',
}

export const LOGO_TYPE = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

export const INTERNAL_HOSTS = [
  process.env.REACT_APP_DEV_HOST,
  process.env.REACT_APP_QA_HOST,
  process.env.REACT_APP_QA_HOST2,
  process.env.REACT_APP_SIT_HOST,
  process.env.REACT_APP_SIT_HOST2,
]

export const ICON_NAMES = {
  PLAYER: 'player',
  TABLE: 'table',
  INFO: 'info',
  CHAT: 'chat',
  CONTACT: 'contact',
  MOUSE: 'mouse',
  FULLSCREEN: 'fullscreen',
  LAYOUT_SMALL: 'layoutSmall',
  LAYOUT_MEDIUM: 'layoutMedium',
  LAYOUT_LARGE: 'layoutLarge',
  BELL: 'bell',
  SETTING: 'setting',
  IOSCHECK: 'ios-check',
  IOSCROSS: 'ios-cross',
  SOUND: 'sound',
  MUTE: 'mute',
  HISTORY: 'history',
}

export const BET_TYPES = {
  PLAYER_PAIR: 'playerPair',
  PLAYER: 'player',
  TIE: 'tie',
  BANKER: 'banker',
  BANKER_PAIR: 'bankerPair',
  PERFECT_PAIR: 'perfectPair',
  BANKER_BONUS: 'bankerBonus',
  PLAYER_BONUS: 'playerBonus',
  EITHER_PAIR: 'eitherPair',
  SUPER_6: 'super6',
  PLAYER_UNDER: 'playerUnder',
  PLAYER_OVER: 'playerOver',
  BANKER_UNDER: 'bankerUnder',
  BANKER_OVER: 'bankerOver',
  OVER: 'over',
  UNDER: 'under',
}

export const BET_TYPE_MAPS = {
  1: BET_TYPES.BANKER,
  2: BET_TYPES.PLAYER,
  3: BET_TYPES.TIE,
  4: BET_TYPES.BANKER_PAIR,
  5: BET_TYPES.PLAYER_PAIR,
  6: BET_TYPES.SUPER_6,
  7: BET_TYPES.EITHER_PAIR,
  8: BET_TYPES.PERFECT_PAIR,
  11: BET_TYPES.BANKER_BONUS,
  12: BET_TYPES.PLAYER_BONUS,
  30: BET_TYPES.PLAYER_OVER,
  31: BET_TYPES.PLAYER_UNDER,
  28: BET_TYPES.BANKER_OVER,
  29: BET_TYPES.BANKER_UNDER,
  32: BET_TYPES.OVER,
  33: BET_TYPES.UNDER,
}

export const OVER_UNDER_ALL_TYPES = [
  BET_TYPES.TIE,
  BET_TYPES.PLAYER,
  BET_TYPES.BANKER,
  BET_TYPES.PLAYER_PAIR,
  BET_TYPES.BANKER_PAIR,
  BET_TYPES.PLAYER_OVER,
  BET_TYPES.PLAYER_UNDER,
  BET_TYPES.BANKER_OVER,
  BET_TYPES.BANKER_UNDER,
  BET_TYPES.OVER,
  BET_TYPES.UNDER,
]

export const ONLY_OVER_UNDER_TYPES = [
  BET_TYPES.PLAYER_OVER,
  BET_TYPES.PLAYER_UNDER,
  BET_TYPES.BANKER_OVER,
  BET_TYPES.BANKER_UNDER,
  BET_TYPES.OVER,
  BET_TYPES.UNDER,
]

export const NORMAL_CHILDREN_TYPES = [PropTypes.element, PropTypes.array, PropTypes.func]
export const BGM_PLAYBACK_KEY = 'bgm_playback'

export const GAME_MODE_TYPES = {
  DEFAULT: 'default',
  OVER_UNDER: 'overunder',
}

export const SINGLE_TABLE_GAME = ['marble']
