import getAxios from 'services/createAxiosClient'
import jsonToFormData from 'utils/jsonToFormData'

export const getAuthToken = (data) => {
  return getAxios().post('/api/lobby/auth', jsonToFormData(data))
}

export const getCustomInfo = (data) => {
  return getAxios().get('/api/lobby/logo', { params: data })
}
