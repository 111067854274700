import { createSlice, createAction } from '@reduxjs/toolkit'
import { GAME_MODE_TYPES } from 'constants/index'
import { parse } from 'query-string'
import checkIframe from 'utils/checkIframe'
import updateStateDraft from 'utils/updateStateDraft'

export const updateSubscriptions = createAction('UpdateSubscriptions')
export const requestOrderHistory = createAction('global/requestOrderHistory')
export const requestOrderSummary = createAction('global/requestOrderSummary')

export const cardsState = {
  P1: '',
  P2: '',
  P3: '',
  B1: '',
  B2: '',
  B3: '',
}

const { inIframe, inInternalIframe } = checkIframe()
export const slice = createSlice({
  name: 'global',
  initialState: {
    showComingSoon: false,
    from: parse(window.location.search).from,
    host: process.env.REACT_APP_HOST || window.location.host,
    wsScheme: window.location.protocol === 'http:' ? 'ws:' : 'wss:',
    inIframe,
    inInternalIframe,
    theme: 'light',
    category: '',
    categories: [],
    casinoPlayerCount: 0,
    balance: 0,
    currencyCode: 'CNY',
    currencySymbol: '¥',
    preBetAmount: 0,
    goodRoadCount: 0,
    mainSoundAllowed: true,
    mainVolume: 100,
    bgmAllowed: false,
    bgmVolume: 50,
    effectSoundAllowed: true,
    effectVolume: 50,
    isBlur: false,
    gameMode: GAME_MODE_TYPES.DEFAULT,
  },
  reducers: {
    getGlobalSetting: (state, { payload }) => {
      updateStateDraft(state, payload)
    },
    updateGlobalSetting: (state, { payload }) => {
      updateStateDraft(state, payload)
    },
    setTheme: (state) => {
      state.theme = !state.theme
    },
    updateMarbleMaintain: (state, { payload }) => {
      state.categories.find((el) => el.categoryId === 'marble').ribbon.maintain = payload
    },
    updateCategory: (state, { payload }) => {
      state.category = payload.category
    },
    updateCategories: (state, { payload }) => {
      if (payload.categories) {
        state.categories = payload.categories
      } else {
        console.error(`action [updateCategories] payload`, payload.categories)
      }
    },
    updatePlayerCount: (state, { payload }) => {
      state.casinoPlayerCount = payload.total
    },
    updateBalance: (state, { payload }) => {
      state.balance = payload.balance
      state.currencyCode = payload.currencyCode
      state.currencySymbol = payload.currencySymbol
    },
    updatePreBetAmount: (state, { payload }) => {
      state.preBetAmount = payload
    },
    updateGoodRoadCount: (state, { payload }) => {
      state.goodRoadCount = payload.count
    },
    updateWindowOnBlur: (state, { payload }) => {
      state.isBlur = payload
    },
    updateComingSoon: (state, { payload }) => {
      state.showComingSoon = payload
    },
  },
})

export const {
  updateMarbleMaintain,
  updateComingSoon,
  setTheme,
  updateCategory,
  updateCategories,
  updatePlayerCount,
  updateBalance,
  updatePreBetAmount,
  updateGoodRoadCount,
  updateGlobalSetting,
  getGlobalSetting,
  updateWindowOnBlur,
} = slice.actions

export default slice.reducer
