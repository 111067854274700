import { lazy, Suspense } from 'react'
import history from 'utils/history'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { useInjectSaga } from 'redux-injectors'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import rootSaga from 'sagas/rootSaga'
import { ROUTE_PATH } from './constants'

const HomePage = lazy(() => import('pages/HomePage/HomePage'))

const Routes = () => {
  useInjectSaga({ key: 'root', saga: rootSaga })
  const allowingPath = Object.values(ROUTE_PATH).join('|')
  return (
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <Suspense fallback={<div />}>
          <Switch>
            <Route path={`/lobby/:categoryId(${allowingPath})`} component={HomePage} />
            <Route path="*">
              <Redirect to={`/lobby/${ROUTE_PATH.BACCARAT}${window.location.search}`} />
            </Route>
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </ErrorBoundary>
  )
}

export default Routes
