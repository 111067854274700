import React, { Children } from 'react'
import PropTypes from 'prop-types'
import StyleContext from 'isomorphic-style-loader/StyleContext'
import { Provider } from 'react-redux'
import SVGsymbol from 'components/SVGsymbol/SVGsymbol'
import { DialogServiceProvide } from 'utils/dialog'
import SoundEffectProvider from 'provider/SoundEffectProvider'
import ThemeProvider from './theme/ThemeProvider'

const App = ({ children, insertCss, store }) => {
  return (
    <StyleContext.Provider value={{ insertCss }}>
      <Provider store={store}>
        <ThemeProvider>
          <SoundEffectProvider>
            <DialogServiceProvide>{Children.only(children)}</DialogServiceProvide>
          </SoundEffectProvider>
        </ThemeProvider>
      </Provider>
      <SVGsymbol />
    </StyleContext.Provider>
  )
}

App.propTypes = {
  store: PropTypes.object,
  children: PropTypes.element.isRequired,
  insertCss: PropTypes.func.isRequired,
}

export default App
