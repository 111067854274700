import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Dark from 'theme/Dark/Dark'
import Light from 'theme/Light/Light'
import useOrientationDetect from 'lib/hooks/useOrientationDetect'
import { updateOrientation } from 'reducers/layout'
import useStyles from 'lib/hooks/useStyles'
import globalStyles from 'index.scss'

const themeMap = {
  light: <Light />,
  dark: <Dark />,
}

const ThemeProvider = ({ children }) => {
  const theme = useSelector((state) => state.global.theme)
  useStyles(globalStyles)
  useOrientationDetect(updateOrientation)
  return (
    <>
      {children}
      {themeMap[theme]}
    </>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.element,
}

export default ThemeProvider
