import React from 'react'
import ReactDOM from 'react-dom'
import 'services/createAxiosClient'
import App from './App'
import Routes from './Routes'
import { store } from './store/configureStore'
import './i18n'
import reportWebVitals from './reportWebVitals'
import insertCss from './utils/insertCss'

/* 用於正式環境顯示版號 */
console.warn(`version: ${process.env.REACT_APP_VERSION}`)

ReactDOM.render(
  <React.StrictMode>
    <App insertCss={insertCss} store={store}>
      <Routes />
    </App>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
