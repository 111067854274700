import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import createReducer from 'reducers'
import createSagaMiddleware from 'redux-saga'
import { createInjectorsEnhancer } from 'redux-injectors'
import history from 'utils/history'
import { routerMiddleware } from 'connected-react-router'
import { INTERNAL_HOSTS } from 'constants/index'

const logger = createLogger({
  diff: true,
  collapsed: true,
})
const sagaMiddleware = createSagaMiddleware()
const runSaga = sagaMiddleware.run
let middlewares = [routerMiddleware(history), sagaMiddleware]

const isDebugMode =
  INTERNAL_HOSTS.includes(window.location.host) || process.env.NODE_ENV === 'development'

if (isDebugMode) {
  middlewares = [...middlewares, logger]
}
export const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), ...middlewares],
  devTools: isDebugMode,
  enhancers: [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ],
})
