const jsonParser = (data) => {
  try {
    if (typeof data !== 'string') return data
    return JSON.parse(data)
  } catch (e) {
    return data
  }
}

export default jsonParser
